<template>
  <v-container grid-list-xl>
    <v-layout xs12 v-if="!loading">
      <v-flex xs8 style="padding-left: 0px;">
        <view-title
          :search="true"
          :dropDownItems="all_sectors"
          :activeItem="active_sector"
          label="Search Groups"
          @primaryItemChanged="goToNewSector"
          :secondaryItemsList="companiesWithGlobalMenu"
          :secondaryActiveItem="company"
          secondaryItemLabel="Search Global Brands"
          @secondaryItemChanged="goToNewCompany"
        />
      </v-flex>
    </v-layout>
    <v-layout style="width: 660px;">
      <v-text-field
        label="Search Local Menus"
        single-line
        solo
        prepend-inner-icon="mdi-magnify"
        clearable
        @input="updateSearchValue"
        :value="search"
      />
    </v-layout>
    <v-layout>
      <v-card class="v-card-custom w100">
        <v-layout row wrap>
          <loading-wrapper :loading="!isCompanyLoaded" mt="20vh" :size="80">
            <v-flex xs12 class="Subtitle-1-Secondary-Left">Global Menu</v-flex>
            <v-flex>
              <menu-list-item
                :record="company"
                :key="'global'"
                :localBrand="false"
                :isGlobalMod="false"
              />

              <menu-list-item
                :record="company"
                :key="'global-mod'"
                :localBrand="false"
                :isGlobalMod="true"
              />
            </v-flex>

            <v-flex xs12 class="Subtitle-1-Secondary-Left">Local Menus</v-flex>
            <template v-if="showListItems">
              <v-flex>
                <menu-list-item
                  v-for="(locationBrand, index) of filteredBrandsOnPage"
                  :record="locationBrand"
                  :key="locationBrand.id"
                  :localBrand="true"
                  :isGlobalMod="true"
                  :index="index"
                />
              </v-flex>
            </template>
            <v-flex xs12 v-else class="Body-2-Primary-On-Surface-Center">
              There are no local menus under this global menu.
            </v-flex>
            <div class="text-xs-center flex xs12 display-flex justify-center">
              <v-pagination
                v-model="page"
                :total-visible="10"
                :length="numberOfPages"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
              ></v-pagination>
            </div>
          </loading-wrapper>
        </v-layout>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import filters from 'vue2-filters';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import menuListItem from './menuListItem';

export default {
  name: 'Brand',
  components: {
    menuListItem,
  },
  mixins: [filters.mixin],
  async beforeRouteLeave(to, from, next) {
    /* This check is required since the watch block in
       menu/sector.vue updates title only when sector changes
   */
    if (to.params.sector_id === this.active_sector.id) {
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 1,
        title: this.active_sector.name,
        last: true,
        to: { name: 'menu-sector-info', params: { sector_id: this.active_sector.id } },
      });
    }
    return next();
  },
  async mounted() {
    this.$store.dispatch('sectors/fetchSectors');
    this.fetchSectorMenu({
      id: this.$route.params.sector_id,
    });
    this.init();
  },
  data: () => ({
    search: '',
    page: 1,
    itemsPerPage: 10,
  }),
  computed: {
    ...mapState('sectors', ['active_sector', 'all_sectors']),
    ...mapState('adminPanel', ['loading']),
    ...mapGetters('menus', ['getCompanyMenus', 'getSectorMenus']),
    ...mapGetters('sectors', ['getCompany']),
    isCompanyLoaded() {
      return this.company && this.company.locations;
    },
    companiesWithGlobalMenu() {
      if (
        !this.active_sector ||
        this.active_sector.id !== this.$route.params.sector_id ||
        !this.sectorMenus ||
        !this.sectorMenus.length
      ) {
        return [];
      }
      const menusSectorCompanySet = new Set();
      this.sectorMenus.forEach((menu) => {
        menusSectorCompanySet.add(menu.company);
      });
      const global = [];

      // determine which companys have menus
      this.active_sector.companies.forEach((company) => {
        if (menusSectorCompanySet.has(company.id)) {
          global.push(company);
        }
      });
      return global;
    },
    sectorMenus() {
      return this.getSectorMenus(this.$route.params.sector_id);
    },
    company() {
      return this.getCompany(this.$route.params.company_id);
    },
    companyMenus() {
      return this.getCompanyMenus(this.$route.params.company_id);
    },
    filteredBrands() {
      let filtered = [];
      const linkedBrands =
        this.companyWithGlobalMenus &&
        this.companyWithGlobalMenus.localbrands &&
        this.companyWithGlobalMenus.localbrands.linked;
      if (!linkedBrands) return filtered;

      filtered = cloneDeep(this.companyWithGlobalMenus.localbrands.linked);
      if (this.search && this.search.length > 0) {
        const searchValue = (this.search || '').toLowerCase();
        filtered = filtered.filter((brand) =>
          `${brand.location_description || ''} ${brand.location && brand.location.name}`
            .toLowerCase()
            .includes(searchValue),
        );
      }
      return filtered;
    },
    filteredBrandsOnPage() {
      const _from = (this.page - 1) * this.itemsPerPage;
      const _to = this.page * this.itemsPerPage;
      return this.filteredBrands.slice(_from, _to);
    },
    showListItems() {
      return this.filteredBrands.length > 0 && this.company;
    },
    numberOfPages() {
      return Math.ceil(this.filteredBrands.length / this.itemsPerPage);
    },
    companyWithGlobalMenus() {
      this.$store.commit('adminPanel/setLoading', true);
      const { company } = this;
      const { companyMenus } = this;
      if (!company || !company.name || !companyMenus || !companyMenus.length) return [];
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 3,
        title: `${company.name}`,
        last: true,
        to: { name: 'brand', params: this.$route.params },
      });
      this.$store.commit('adminPanel/setLoading', false);
      return this.sortMenusInCompany(
        cloneDeep(company),
        cloneDeep(companyMenus.map((m) => ({ ...m, groups: undefined }))),
      );
    },
  },
  watch: {
    company(newValue) {
      if (!newValue || !newValue.id) return;
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 3,
        title: `${newValue.name}`,
        last: true,
        to: { name: 'brand', params: this.$route.params },
      });
    },
  },
  methods: {
    ...mapActions('sectors', ['fetchCompany']),
    ...mapActions('menus', ['fetchCompanyMenu', 'fetchSectorMenu']),
    ...mapActions('users', ['fetchUser']),
    async init() {
      this.page = 1;
      await Promise.all([
        this.fetchCompanyMenu({
          id: this.$route.params.company_id,
          nocache: true,
        }),
        this.fetchCompany({ id: this.$route.params.company_id, nocache: true }),
      ]);
    },
    goToNewSector(group) {
      this.$router.push({
        name: 'menu-sector-info',
        params: {
          sector_id: group.id,
        },
      });
    },
    goToNewCompany(company) {
      this.search = '';
      this.$router
        .push({
          name: 'brand',
          params: {
            sector_id: company.sector,
            company_id: company.id,
          },
        })
        .then(this.init);
    },
    sortMenusInCompany(locationCompany, companyMenus) {
      const globalMenus = [];

      // map out all current brands with menus
      const menuCompanyBrandMap = new Map();
      companyMenus.forEach((menu) => {
        if (menu.location_brand && menu.is && menu.is.linked) {
          if (!menuCompanyBrandMap.has(menu.location_brand)) {
            menuCompanyBrandMap.set(menu.location_brand, []);
          }
          menuCompanyBrandMap.get(menu.location_brand).push(menu.parent_id);
        }
        if (!menu.parent_id) {
          globalMenus.push(menu.id);
        }
      });

      const linked = [];

      // loop through all brands to determine if they have a menu created yet
      if (locationCompany.locations) {
        locationCompany.locations.forEach((location) => {
          location.brands.forEach((brand) => {
            brand.location = {
              name: location.name,
            };
            if (menuCompanyBrandMap.has(brand.id)) {
              // determine missing menus
              brand.localMenu = menuCompanyBrandMap.get(brand.id);
              linked.push(brand);
            }
          });
        });
      }
      locationCompany.globalMenus = globalMenus;
      locationCompany.localbrands = { linked };
      return locationCompany;
    },
    // eslint-disable-next-line
    updateSearchValue: debounce(function(value) {
      this.search = value;
      this.page = 1;
    }, 350),
  },
};
</script>
