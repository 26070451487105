import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "grid-list-xl": "" } },
    [
      !_vm.loading
        ? _c(
            VLayout,
            { attrs: { xs12: "" } },
            [
              _c(
                VFlex,
                { staticStyle: { "padding-left": "0px" }, attrs: { xs8: "" } },
                [
                  _c("view-title", {
                    attrs: {
                      search: true,
                      dropDownItems: _vm.all_sectors,
                      activeItem: _vm.active_sector,
                      label: "Search Groups",
                      secondaryItemsList: _vm.companiesWithGlobalMenu,
                      secondaryActiveItem: _vm.company,
                      secondaryItemLabel: "Search Global Brands"
                    },
                    on: {
                      primaryItemChanged: _vm.goToNewSector,
                      secondaryItemChanged: _vm.goToNewCompany
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        VLayout,
        { staticStyle: { width: "660px" } },
        [
          _c(VTextField, {
            attrs: {
              label: "Search Local Menus",
              "single-line": "",
              solo: "",
              "prepend-inner-icon": "mdi-magnify",
              clearable: "",
              value: _vm.search
            },
            on: { input: _vm.updateSearchValue }
          })
        ],
        1
      ),
      _c(
        VLayout,
        [
          _c(
            VCard,
            { staticClass: "v-card-custom w100" },
            [
              _c(
                VLayout,
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "loading-wrapper",
                    {
                      attrs: {
                        loading: !_vm.isCompanyLoaded,
                        mt: "20vh",
                        size: 80
                      }
                    },
                    [
                      _c(
                        VFlex,
                        {
                          staticClass: "Subtitle-1-Secondary-Left",
                          attrs: { xs12: "" }
                        },
                        [_vm._v("Global Menu")]
                      ),
                      _c(
                        VFlex,
                        [
                          _c("menu-list-item", {
                            key: "global",
                            attrs: {
                              record: _vm.company,
                              localBrand: false,
                              isGlobalMod: false
                            }
                          }),
                          _c("menu-list-item", {
                            key: "global-mod",
                            attrs: {
                              record: _vm.company,
                              localBrand: false,
                              isGlobalMod: true
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        VFlex,
                        {
                          staticClass: "Subtitle-1-Secondary-Left",
                          attrs: { xs12: "" }
                        },
                        [_vm._v("Local Menus")]
                      ),
                      _vm.showListItems
                        ? [
                            _c(
                              VFlex,
                              _vm._l(_vm.filteredBrandsOnPage, function(
                                locationBrand,
                                index
                              ) {
                                return _c("menu-list-item", {
                                  key: locationBrand.id,
                                  attrs: {
                                    record: locationBrand,
                                    localBrand: true,
                                    isGlobalMod: true,
                                    index: index
                                  }
                                })
                              }),
                              1
                            )
                          ]
                        : _c(
                            VFlex,
                            {
                              staticClass: "Body-2-Primary-On-Surface-Center",
                              attrs: { xs12: "" }
                            },
                            [
                              _vm._v(
                                "\n            There are no local menus under this global menu.\n          "
                              )
                            ]
                          ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-xs-center flex xs12 display-flex justify-center"
                        },
                        [
                          _c(VPagination, {
                            attrs: {
                              "total-visible": 10,
                              length: _vm.numberOfPages,
                              "prev-icon": "mdi-menu-left",
                              "next-icon": "mdi-menu-right"
                            },
                            model: {
                              value: _vm.page,
                              callback: function($$v) {
                                _vm.page = $$v
                              },
                              expression: "page"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }